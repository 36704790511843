<template>
  <section class="mt-8">
    <div class="mt-8">
      <label for="businessName">Business Name</label>
      <input
        type="text"
        class="input"
        id="businessName"
        readonly
        placeholder="Enter the name of your business here"
        v-model="form.businessName"
      />
    </div>

    <div class="mt-8">
      <label for="tin">JTB tin</label>
      <input
        type="text"
        class="input"
        id="tin"
        readonly
        placeholder="Enter your JTB tin"
        v-model="form.tin"
      />
    </div>

    <div class="mt-8">
      <label for="businessType">Business type</label>
      <v-select
        placeholder="Choose a business type"
        label="label"
        v-model="form.businessType"
        :reduce="(b) => b.value"
        :options="$store.state.businessTypes"
      ></v-select>
    </div>

    <div class="mt-8">
      <label for="businessType">Business Category</label>
      <v-select
        label="label"
        placeholder="Choose a business category"
        v-model="form.businessCategory"
        :reduce="(b) => b.value"
        :options="$store.state.businessCategories"
      ></v-select>
    </div>

    <div class="mt-8">
      <label for="businessDescription">Business description</label>
      <textarea
        name="businessDescription"
        id="businessDescription"
        cols="30"
        rows="5"
        placeholder="Describe your business here"
        v-model="form.businessDescription"
      ></textarea>
    </div>

    <div class="mt-6">
      <button
        :disabled="!proceedReady || proceeding"
        type="submit"
        @click="proceed"
        class="form-button"
      >
        <span class="mr-1">Proceed</span>
        <spinner v-if="proceeding" />
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "Register1",

  async mounted() {
    await this.$store.dispatch("getBusinessTypes");
  },

  data() {
    return {
      proceeding: false,
      form: {
        businessName: this.$store.state.newRegister.registered_name,
        tin: this.$store.state.newRegister.tin,
        businessType: null,
        businessCategory: null,
        businessDescription: null,
      },
    };
  },

  computed: {
    proceedReady() {
      return (
        this.form.businessType &&
        this.form.businessDescription &&
        this.form.businessCategory
      );
    },
  },

  methods: {
    async proceed() {
      try {
        this.proceeding = true;

        this.$store.commit("addTonewRegister", this.form);

        this.proceeding = false;

        this.$router.push({
          name: "register-registered-business",
          query: { st: 3 },
        });
      } catch (err) {
        console.log(err);
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped></style>
