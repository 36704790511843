var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 lg:px-18 lg:pt-8 block lg:flex"},[_c('section',{staticClass:"w-12/12 lg:w-8/12"},[_c('div',{staticClass:"flex gap-2 items-center"},[_c('button',{staticClass:"py-2 px-3",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('img',{staticClass:"h-6 w-6",attrs:{"src":require("@/assets/images/icons/arrow-top-left-sharp.svg"),"alt":"icons"}})]),_c('h4',{staticClass:"font-bold text-base lg:text-xl text-pineGray"},[_c('span',{},[_vm._v(_vm._s(_vm.signage.asset_code)+" ")]),_c('span',[_vm._v("Asset preview")])])]),(_vm.getting)?_c('div',{staticClass:"flex justify-center w-full mt-7"},[_c('spinner',{staticClass:"w-14 h-14",attrs:{"color":"ansGreen"}})],1):_c('div',[(_vm.showSubDetails)?_c('div',[(_vm.signage.valid)?_c('div',{staticClass:"gredy valid"},[_vm._m(0),_c('div',[_vm._v("12 Dec, 2023")])]):_c('div',{staticClass:"gredy invalid"},[_vm._m(1),_c('router-link',{attrs:{"to":{ name: 'invoices' }}},[_vm._v("View")])],1)]):_vm._e(),_c('div',[_c('accordion',{staticClass:"mt-8",attrs:{"title":"Business details"}},[_c('BusinessDetails',{attrs:{"business":{
              businessName: _vm.signage.business_name,
              businessType: _vm.signage.signage_business_type,
              businessDesc: _vm.signage.asset_description,
            }}})],1),_c('accordion',{staticClass:"mt-8",attrs:{"title":"Signage details"}},[_c('SignageDetails',{attrs:{"sign":_vm.signage}})],1),(_vm.showSubDetails)?_c('accordion',{staticClass:"mt-8",attrs:{"title":"Subscription details"}},[_c('SubDetails')],1):_vm._e(),_c('accordion',{staticClass:"mt-8",attrs:{"title":`Invoices (${_vm.invoices.length})`}},[_c('Invoices',{attrs:{"invoices":_vm.invoices}})],1)],1)])]),_c('section',{staticClass:"w-12/12 lg:w-4/12 ml-0 lg:ml-5"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center text-xs gap-2"},[_c('img',{attrs:{"src":require("@/assets/images/icons/dollar_white.svg"),"alt":"icons"}}),_c('p',[_vm._v("Subscription valid till")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center text-xs gap-2"},[_c('img',{attrs:{"src":require("@/assets/images/icons/dollar_red.svg"),"alt":"icons"}}),_c('p',[_vm._v("Outstanding Invoices (2)")])])
}]

export { render, staticRenderFns }