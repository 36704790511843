<template>
  <section class="mt-8">
    <div class="mt-8">
      <label for="jtbTin">JTB tin</label>
      <input
        type="text"
        class="input"
        id="jtbTin"
        placeholder="Enter your JTB tin"
        v-model="form.tin"
      />
    </div>

    <div class="mt-6">
      <button
        :disabled="!tinReady || tinProcessing"
        type="submit"
        @click="processTin"
        class="form-button"
      >
        <span class="mr-1">Proceed</span>
        <spinner v-if="tinProcessing" />
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "JtbTin",

  data() {
    return {
      tinProcessing: false,
      form: {
        tin: null,
        userId: this.$store.state.user.id,
      },
    };
  },

  computed: {
    tinReady() {
      return this.form.tin;
    },
  },

  methods: {
    async processTin() {
      try {
        this.tinProcessing = true;

        const res = await this.$http.post("/verify-jtb", this.form);
        this.tinProcessing = false;

        if (!res) {
          return;
        }

        const { data } = res;
        const usedD = data.data ? data.data : data.message;
        this.$store.commit("addTonewRegister", usedD);

        if (data.code == "01") {
          this.$router.push({
            name: "register-registered-business",
            query: { st: 4 },
          });
          return;
        }

        this.$router.push({
          name: "register-registered-business",
          query: { st: 2 },
        });
      } catch (err) {
        console.log(err);
        this.tinProcessing = false;
      }
    },
  },
};
</script>

<style scoped></style>
