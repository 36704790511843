<template>
  <div class="p-4 lg:px-18 lg:pt-8 block lg:flex">
    <section class="w-12/12 lg:w-8/12">
      <div class="flex gap-2 items-center">
        <button @click="$router.go(-1)" class="py-2 px-3">
          <img
            src="@/assets/images/icons/arrow-top-left-sharp.svg"
            alt="icons"
            class="h-6 w-6"
          />
        </button>

        <h4 class="font-bold text-base lg:text-xl text-pineGray">
          <span class="">{{ signage.asset_code }} </span>
          <span>Asset preview</span>
        </h4>
      </div>

      <div v-if="getting" class="flex justify-center w-full mt-7">
        <spinner class="w-14 h-14" color="ansGreen" />
      </div>

      <div v-else>
        <div v-if="showSubDetails">
          <div v-if="signage.valid" class="gredy valid">
            <div class="flex items-center text-xs gap-2">
              <img src="@/assets/images/icons/dollar_white.svg" alt="icons" />
              <p>Subscription valid till</p>
            </div>

            <div>12 Dec, 2023</div>
          </div>

          <div v-else class="gredy invalid">
            <div class="flex items-center text-xs gap-2">
              <img src="@/assets/images/icons/dollar_red.svg" alt="icons" />
              <p>Outstanding Invoices (2)</p>
            </div>

            <router-link :to="{ name: 'invoices' }">View</router-link>
          </div>
        </div>

        <div>
          <accordion title="Business details" class="mt-8">
            <BusinessDetails
              :business="{
                businessName: signage.business_name,
                businessType: signage.signage_business_type,
                businessDesc: signage.asset_description,
              }"
            />
          </accordion>

          <accordion title="Signage details" class="mt-8">
            <SignageDetails :sign="signage" />
          </accordion>

          <accordion
            v-if="showSubDetails"
            title="Subscription details"
            class="mt-8"
          >
            <SubDetails />
          </accordion>

          <accordion :title="`Invoices (${invoices.length})`" class="mt-8">
            <Invoices :invoices="invoices" />
          </accordion>
        </div>
      </div>
    </section>

    <section class="w-12/12 lg:w-4/12 ml-0 lg:ml-5"></section>
  </div>
</template>

<script>
import Accordion from "@/components/app/dashboard/Accordion";
import BusinessDetails from "@/components/app/signage/BusinessDetails";
import SignageDetails from "@/components/app/signage/SignageDetails";
import SubDetails from "@/components/app/signage/SubDetails";
import Invoices from "@/components/app/signage/Invoices";
export default {
  name: "ViewSignage",

  components: {
    Accordion,
    BusinessDetails,
    SignageDetails,
    SubDetails,
    Invoices,
  },

  props: {
    id: {
      type: [String, Number],
    },
  },

  async created() {
    await this.getData();
  },

  data() {
    return {
      getting: false,
      invoices: [],
      showSubDetails: false,
      signage: {
        valid: false,
      },
    };
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.post("/get-single-signage", {
          userId: this.$store.getters.userId,
          signage_id: this.id,
        });
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.signage = data.message.signage;
        this.invoices = data.message.invoices;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.gredy {
  @apply flex justify-between items-center mt-3.5 px-4 py-2 rounded-lg;
}
.gredy.valid {
  @apply bg-ansGreen text-white;
}
.gredy.valid div:last-child {
  @apply bg-ansLemon font-medium text-xs px-2.5 py-1;
  border-radius: 4px;
}
.gredy.invalid {
  @apply bg-red-100 text-red-700;
}
.gredy.invalid a {
  @apply bg-ansLemon block text-white font-medium text-xs px-4 py-1 rounded;
}
</style>
