<template>
  <div>
    <Search :showFilter="false" />

    <div>
      <div
        v-for="(sign, n) in signages"
        :key="n"
        class="flex justify-between items-center mt-6 px-4 py-2.5 bg-ghostWhite rounded-xl"
      >
        <div class="flex gap-2.5">
          <img src="@/assets/images/icons/tv.svg" alt="icons" />

          <div>
            <h5 class="font-bold">{{ sign.asset_code }}</h5>

            <p v-if="sign.paid" class="text-xs">
              <span class="text-cadet mr-1">Valid till</span>
              <span class="font-semibold">{{ sign.duration }}</span>
            </p>

            <p v-else class="text-red-400 text-xs">
              <span>Payment due</span>
              <span class="font-semibold ml-1">{{ sign.duration }}</span>
            </p>
          </div>
        </div>

        <router-link
          :to="{ name: 'view-signage', params: { id: sign.signage_id } }"
          class="bg-ansLemon block text-white font-medium text-xs px-4 py-1 rounded"
        >
          View
        </router-link>
      </div>
    </div>

    <div class="flex justify-center">
      <button
        class="text-center text-white bg-ansLemon w-full lg:w-80 py-3.5 font-medium rounded-xl mt-6"
      >
        ＋ New signage
      </button>
    </div>
  </div>
</template>

<script>
import Search from "@/components/form/Search";
export default {
  name: "Signages",

  components: {
    Search,
  },

  props: {
    signages: {
      type: Array,
      default: () => [
        {
          asset_code: "GGG23208",
          duration: "10/10/2023",
          paid: 0,
        },
      ],
    },
  },
};
</script>

<style scoped></style>
