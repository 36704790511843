<template>
  <div class="block lg:flex justify-evenly px-8 lg:px-12 mt-7">
    <section class="w-12/12 lg:w-7/12">
      <h2 class="text-4pxl font-bold font-karla text-black">Asset preview</h2>

      <div class="border-t border-turqGreen pt-5 mt-4">
        <h4 class="font-bold font-karla text-darkCharcoal">Business details</h4>

        <div ref="businessName" class="former">
          <p>Business name</p>
          <div>{{ d.businessName }}</div>
        </div>

        <div class="former">
          <p>Business Type</p>
          <div>
            {{
              d.business_type_name
                ? d.business_type_name
                : $store.getters.busType(d.businessType).label
            }}
          </div>
        </div>

        <div class="former">
          <p>Business Category</p>
          <div>
            {{ $store.getters.busCategory(d.businessCategory).label }}
          </div>
        </div>

        <div class="former">
          <p>Business Description</p>
          <div>
            {{ d.businessDescription }}
          </div>
        </div>
      </div>

      <div class="border-t border-turqGreen pt-5 mt-4">
        <h4 class="font-bold font-karla text-darkCharcoal">
          Signage Location details
        </h4>

        <div class="former">
          <p>LGA</p>
          <div>{{ d.localgovt ? d.localgovt : d.lga }}</div>
        </div>

        <div class="former">
          <p>Town</p>
          <div>
            {{ d.city_name ? d.city_name : $store.getters.town(d.town).city }}
          </div>
        </div>

        <div class="former">
          <p>Area</p>
          <div>
            {{ d.area_name ? d.area_name : $store.getters.area(d.area).label }}
          </div>
        </div>

        <div class="former">
          <p>Street</p>
          <div>
            {{
              d.street_name
                ? d.street_name
                : $store.getters.street(d.street).label
            }}
          </div>
        </div>
      </div>

      <div class="border-t border-turqGreen pt-5 mt-4">
        <h4 class="font-bold font-karla text-darkCharcoal">Signage details</h4>

        <div class="former">
          <p>Signage category</p>
          <div>{{ $store.getters.signCategory(d.category).label }}</div>
        </div>

        <div class="former">
          <p>Signage type</p>
          <div>{{ $store.getters.signType(d.signageType).label }}</div>
        </div>

        <!-- <div class="former">
          <p>Subscription plan</p>
          <div>
            {{ d.startDate | moment("Do MMMM, YYYY") }} -
            {{
              d.startDate
                | moment(
                  "add",
                  `${d.subscription.no_of_days} days`,
                  "Do MMMM, YYYY"
                )
            }}
          </div>
        </div> -->
      </div>

      <div class="border-t border-turqGreen pt-5 mt-4">
        <h4 class="font-bold font-karla text-darkCharcoal">
          Signage Cost details
        </h4>

        <div class="former">
          <p>Item Description</p>
          <div>
            {{ $store.getters.signItem(d.signageItem).label }}
          </div>
        </div>

        <div class="former">
          <p>Subscription period</p>
          <div v-if="d.year">
            {{ getDate(d.year) }} - {{ getDate(d.year + 1) }}
          </div>
          <div v-else>
            {{ d.startDate | moment("Do MMMM, YYYY") }} -
            {{
              d.startDate
                | moment(
                  "add",
                  `${d.subscription.no_of_days} days`,
                  "Do MMMM, YYYY"
                )
            }}
          </div>
        </div>

        <div class="former">
          <p>Unit price (&#13217;)</p>
          <div>{{ d.unit_cost | toCurrency }}</div>
        </div>

        <div class="former">
          <p>Total</p>
          <div>{{ d.total_cost | toCurrency }}</div>
        </div>
      </div>

      <div v-if="!d.registered" class="border-t border-turqGreen pt-4 mt-4">
        <button
          ref="regBtn"
          :disabled="registering"
          type="submit"
          @click="registerAsset"
          class="font-medium flex justify-center gap-4 bg-ansLemon text-white w-full py-3.5 rounded-xl"
        >
          <span>Complete asset registration</span>
          <spinner v-if="registering" />
        </button>
      </div>
    </section>

    <section class="w-12/12 lg:w-4/12 mt-8 lg:mt-0">
      <PaymentCard
        v-if="d.registered"
        :paymentInfo="paymentInfo"
        @action="payAction"
      />
    </section>
  </div>
</template>

<script>
import PaymentCard from "@/components/app/PaymentCard.vue";
export default {
  name: "RegisterReview",

  components: {
    PaymentCard,
  },

  data() {
    return {
      registering: false,
    };
  },

  computed: {
    d() {
      return this.$store.state.newRegister;
    },
    paymentInfo() {
      return {
        cost: this.d.total_cost,
        asset_code: this.d.asset_code,
      };
    },
  },

  mounted() {
    let to = 0;
    if (this.d.registered) {
      to = this.$refs.businessName.offsetTop - 300;
    } else {
      to = this.$refs.regBtn.offsetTop;
    }
    window.scroll({
      top: to,
      left: 0,
      behavior: "smooth",
    });
  },

  methods: {
    payAction(action) {
      if (action == "pay") {
        // go to payment page
        console.log("going to payment gateway");
      } else {
        // go to dashboard
        this.$swal({
          icon: "info",
          text: "Signage saved for later",
        });
        this.$router.push({ name: "dashboard" });
      }
    },
    getDate(y) {
      return this.moment(`${y}`).format("DD-MM-YYYY");
    },
    buildBody(fb) {
      return {
        phone: this.$store.getters.userPhone,
        jtbTin: fb.uniqueCode ? fb.uniqueCode : fb.tin,
        businessName: fb.businessName,
        businessType: fb.type ? fb.type : fb.businessType,
        businessDescription: fb.businessDescription,
        businessCategory: fb.businessCategory,
        town: fb.city ? fb.city : fb.town,
        area: fb.area,
        street: fb.street,
        category: fb.category,
        signageType: fb.signageType,
        subscriptionId: fb.subscription.value,
        date: fb.year ? this.getDate(fb.year) : fb.startDate,
        height: fb.signHeight,
        width: fb.signWidth,
        streetNo: fb.street,
        userId: this.$store.getters.userId,
        has_subitems: fb.itemHasSub,
        subitemid: fb.signageSubitem,
        itemid: fb.signageItem,
        virtualNin: fb.vNIN ? fb.vNIN : this.$store.state.profile.vNin,
        nin: this.$store.getters.userNin ? this.$store.getters.userNin : fb.nin,
        businessId: fb.id ? fb.id : fb.business_id,
        is_firstparty: fb.is_firstparty,
      };
    },
    async registerAsset() {
      try {
        const result = await this.$swal({
          icon: "question",
          title: "Are you sure you want to register this asset?",
          text: "This action can not be reversed",
          showDenyButton: true,
          confirmButtonText: "Proceed",
          denyButtonText: "Cancel",
          allowOutsideClick: false,
        });

        if (!result.isConfirmed) {
          return;
        }

        this.registering = true;

        let formBody = this.$store.state.newRegister;
        // format and rename the input
        let url =
          formBody.id || formBody.business_id
            ? "/add-signage"
            : "/self-enumerate";

        formBody = this.buildBody(formBody);

        const res = await this.$http.post(url, formBody);
        this.registering = false;

        if (!res) {
          return;
        }

        const { data } = res;

        const asset = data.asset ? data.asset : data.data;
        this.$store.commit("addTonewRegister", {
          registered: true,
          ...asset,
        });

        // scroll to up
        const to = this.$refs.businessName.offsetTop - 300;
        window.scroll({
          top: to,
          left: 0,
          behavior: "smooth",
        });

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (err) {
        console.log(err);
        this.registering = false;
      }
    },
  },
};
</script>

<style scoped>
.former {
  @apply block lg:flex mt-4 justify-between;
}
.former p {
  @apply font-karla text-xs w-full lg:w-6/12;
  color: #5e6974;
}
.former div {
  @apply font-karla font-bold text-xs rounded-xl p-4 bg-ghostWhite w-full lg:w-6/12;
  color: #101011;
}
</style>
