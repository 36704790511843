<template>
  <div class="bgPage block lg:flex justify-evenly px-4 lg:px-12">
    <section
      class="w-12/12 lg:w-5/12 text-white pt-14 lg:pt-36 block h-full lg:flex flex-col justify-start items-start"
    >
      <h2 class="text-3xl lg:text-6xl font-extrabold font-karla">
        Signage<br />Registration
      </h2>
      <h5 class="font-medium text-base lg:text-4xl font-karla mt-3">
        Let's help you register <br />your Signage
      </h5>
    </section>

    <section class="form">
      <h3 class="text-center font-medium mb-4 text-lg lg:text-2xl">
        Signage for Registered business
      </h3>

      <CirlceDotLines :stages="stages - 1" :currentStage="stage" class="" />

      <Lines class="" />

      <!-- FORM AREA -->
      <JtbTin v-if="stage == 1" />

      <Register1 v-if="stage == 2" />

      <BusinessLocation v-if="stage == 3" />

      <SignageRegister v-if="stage == 4" />
    </section>
  </div>
</template>

<script>
import Lines from "@/components/Lines.vue";
import CirlceDotLines from "@/components/CirlceDotLines.vue";
import JtbTin from "@/components/app/register/JtbTin.vue";
import Register1 from "@/components/app/register/Register1.vue";
import BusinessLocation from "@/components/app/register/BusinessLocation.vue";
import SignageRegister from "@/components/app/register/SignageRegister.vue";
export default {
  name: "RegisteredBusiness",

  components: {
    Lines,
    CirlceDotLines,
    JtbTin,
    Register1,
    BusinessLocation,
    SignageRegister,
  },

  data() {
    return {
      stages: 4,
    };
  },

  async mounted() {
    await this.$store.dispatch("getTownLga");
    await this.$store.dispatch("getSignCategories");
  },

  computed: {
    stage() {
      return this.$route.query.st ? this.$route.query.st : 1;
    },
  },

  methods: {
    next() {
      this.stage = this.stage + 1;
    },
  },
};
</script>

<style scoped>
.form {
  @apply pb-8 pt-6 px-4 lg:pb-24 lg:pt-16 lg:px-10 w-full lg:w-6/12 mt-11 lg:mt-0;
  background: #ffffff;
  border-radius: 2rem;
  max-width: 31.25rem;
}
</style>
