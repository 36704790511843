<template>
  <div class="rounded-xl">
    <div
      class="bg-nishGreen rounded-t-xl py-3 px-4 flex justify-between items-center font-medium"
    >
      <div class="text-white w-8/12 truncate">{{ business.businessName }}</div>
      <router-link
        :to="{ name: 'view-business', params: { id: business.business_id } }"
        class="text-white text-xs text-center w-3/12 rounded bg-ansLemon py-1 px-2"
      >
        View more
      </router-link>
    </div>

    <div class="bg-white p-4 border-body">
      <div class="flex items-center">
        <div>
          <img src="@/assets/images/icons/calendar.svg" alt="icon" />
        </div>

        <div class="font-semibold text-sm text-black ml-2.5">
          Registered on {{ business.dateAdded | moment("DD/MM/YYYY") }}
        </div>
      </div>

      <div class="flex items-center mt-4">
        <div>
          <img src="@/assets/images/icons/location.svg" alt="icon" />
        </div>

        <div class="text-xs text-black ml-2.5 pgray">
          {{ business.street_name }}, {{ business.area_name }},
          {{ business.city }}
        </div>
      </div>

      <div
        class="flex justify-between items-center bg-ghostWhite p-2 mt-4 rounded-lg"
      >
        <div class="text-xs italic font-medium text-romanSilver">
          Active signages
        </div>
        <p
          class="bg-britGray px-5 py-0.5 rounded text-center font-bold text-xs"
        >
          {{ business.total_signages }}
        </p>
        <div>
          <button
            @click="addSignage()"
            class="bg-ansLemon block text-white font-medium text-xs px-4 py-1 rounded"
          >
            + New signage
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessCard",

  props: {
    business: {
      type: Object,
      default: () => ({
        businessName: "Chivalry",
        type: 3,
        id: 43,
        dateAdded: "2023-03-22T00:00:00.000Z",
        businessDescription: "Desdbjbv",
        businessType: "Unregistered Business",
        city: "ABAGANA",
        street_name: "ADAGBE VILLAGE",
        area_name: "ADAGBE",
        total_signages: 1,
      }),
    },
  },

  methods: {
    addSignage() {
      this.$store.commit("addTonewRegister", this.business);
      this.$router.push({
        name: "register-unregistered-business",
        query: { st: 5 },
      });
    },
  },
};
</script>

<style scoped>
.border-body {
  border: 1px solid #9cd1b8;
  border-radius: 0px 0px 12px 12px;
}
.pgray {
  color: #5e6974;
}
</style>
