<template>
  <div>
    <div class="former">
      <p>Business name</p>
      <div>{{ business.businessName }}</div>
    </div>

    <div v-if="showTin" class="former">
      <p>JTB Tin</p>
      <div>{{ business.id }}</div>
    </div>

    <div class="former">
      <p>Business Type</p>
      <div>{{ business.businessType }}</div>
    </div>

    <div class="former">
      <p>Business Description</p>
      <div>
        {{ business.businessDesc }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessDetails",

  props: {
    business: {
      type: Object,
      default: () => ({
        businessName: null,
        businessType: null,
        businessDesc: null,
        id: null,
      }),
    },
  },

  data() {
    return {
      showTin: false,
    };
  },
};
</script>

<style scoped>
.former {
  @apply block mt-4;
}
.former p {
  @apply font-redhat text-xs w-full;
  color: #5e6974;
}
.former div {
  @apply font-redhat font-bold text-xs rounded-xl p-4 bg-ghostWhite w-full;
  color: #101011;
}
</style>
