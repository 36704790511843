<template>
  <section>
    <div class="pt-8">
      <label for="nin">NIN</label>
      <input
        autofocus
        type="number"
        class="input"
        id="nin"
        pattern="/^-?\d+\.?\d*$/"
        onKeyPress="if(this.value.length==11) return false;"
        placeholder="Enter your 11-digit NIN"
        v-model="form.nin"
      />
    </div>

    <div class="mt-6">
      <button
        :disabled="!ninReady || ninProcessing"
        type="submit"
        @click="processNin"
        class="form-button"
      >
        <span class="mr-1">Proceed</span>
        <spinner v-if="ninProcessing" />
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "Nin",

  data() {
    return {
      ninProcessing: false,
      form: {
        nin: null,
      },
    };
  },

  mounted() {
    this.$store.commit("clearRegister");
  },

  computed: {
    ninReady() {
      return this.form.nin && this.form.nin.length == 11;
    },
  },

  methods: {
    async processNin() {
      try {
        this.ninProcessing = true;

        this.$store.commit("addTonewRegister", this.form);

        this.ninProcessing = false;

        this.$router.push({
          name: "register-unregistered-business",
          query: { st: 2 },
        });
      } catch (err) {
        console.log(err);
        this.ninProcessing = false;
      }
    },
  },
};
</script>

<style scoped></style>
