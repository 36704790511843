<template>
  <div>
    <div v-if="showit" class="former">
      <p>LGA</p>
      <div>{{ sign.city }}</div>
    </div>

    <div class="former">
      <p>Town</p>
      <div>{{ sign.city }}</div>
    </div>

    <div class="former">
      <p>Area</p>
      <div>{{ sign.area_name }}</div>
    </div>

    <div class="former">
      <p>Street</p>
      <div>{{ sign.street_name }}</div>
    </div>

    <hr class="bg-turqGreen h-0.5 mt-4" />

    <div class="former">
      <p>Signage category</p>
      <div>Government</div>
    </div>

    <div class="former">
      <p>Signage type</p>
      <div>{{ sign.asset_type }}</div>
    </div>

    <div class="former">
      <p>Subscription plan</p>
      <div>
        {{ sign.dateof_subscription | moment("Do MMMM, YYYY") }} -
        {{ sign.duration | moment("Do MMMM, YYYY") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignageDetails",

  props: {
    sign: {
      type: Object,
      default: () => ({
        asset_code: "GL9788000",
        enumerated_on: "2023-04-01T06:39:49.000Z",
        area_name: "ADAGBE",
        street_name: "OKOYE OGBALU LANE",
        signage_business_type: "Limited Liability",
        asset_type: "Banners",
        city: "ABAGANA",
        id: 48,
        subscriber_id: "120",
        StateID: null,
        NIN: "12345",
        amount: "0",
        dateof_subscription: "2020-01-01",
        duration: "2020-12-31",
        subscription_status: 1,
        is_autorenewal: 0,
        created_at: "2023-04-01T06:39:49.000Z",
        idsignage_invoices: 160,
        tax_id: "12345",
        description: "Describe ",
        year: 2020,
        created_on: "2023-04-01T06:39:49.000Z",
        paid: 0,
      }),
    },
  },

  data() {
    return {
      showit: false,
    };
  },
};
</script>

<style scoped>
.former {
  @apply block mt-4;
}
.former p {
  @apply font-redhat text-xs w-full;
  color: #5e6974;
}
.former div {
  @apply font-redhat font-bold text-xs rounded-xl p-4 bg-ghostWhite w-full;
  color: #101011;
}
</style>
