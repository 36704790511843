<template>
  <div class="p-4 lg:px-18 lg:pt-8 block lg:flex">
    <section class="w-12/12 lg:w-8/12">
      <div class="flex justify-between items-center">
        <div class="flex gap-2 items-center">
          <button @click="$router.go(-1)" class="py-2 px-3">
            <img
              src="@/assets/images/icons/arrow-top-left-sharp.svg"
              alt="icons"
              class="h-6 w-6"
            />
          </button>
          <div class="text-xl font-bold text-pineGray">Your Signages</div>
        </div>

        <div>
          <div class="rounded-lg bg-green-200 text-ansGreen text-xs py-2 px-5">
            {{ signages.length }}
          </div>
        </div>
      </div>

      <Search v-model="searchTerm" class="mt-2" />

      <div>
        <div v-if="getting" class="flex justify-center w-full mt-7">
          <spinner class="w-20 h-20" color="ansGreen" />
        </div>

        <div v-else class="signages">
          <SignageCard
            v-for="(signage, n) in filteredSignages"
            :key="n"
            :signage="signage"
          />
        </div>
      </div>

      <div v-if="showPage" class="flex justify-center mt-5">
        <Pagination
          :total-pages="totalPages"
          :total="total"
          :per-page="perPage"
          :current-page="currentPage"
          :has-more-pages="hasMorePages"
          @pagechanged="showMore"
        />
      </div>
    </section>

    <section class="w-12/12 lg:w-4/12 ml-0 lg:ml-5">
      <GreenBox class="hidden lg:flex" />
    </section>
  </div>
</template>

<script>
import Search from "@/components/form/Search";
import GreenBox from "@/components/app/dashboard/GreenBox.vue";
import Pagination from "@/components/form/Pagination.vue";
import SignageCard from "@/components/app/dashboard/SignageCard.vue";
export default {
  name: "Signages",

  components: {
    Search,
    GreenBox,
    Pagination,
    SignageCard,
  },

  async created() {
    await this.getData();
  },

  data() {
    return {
      showPage: false,
      page: 1,
      totalPages: 4,
      total: 40,
      perPage: 10,
      currentPage: 1,
      hasMorePages: true,
      getting: false,
      signages: [],
      searchTerm: null,
    };
  },

  computed: {
    filteredSignages() {
      if (!this.searchTerm) return this.signages;

      return this.signages.filter((sign) => {
        return Object.values(sign).find((vs) => {
          return `${vs}`.toLowerCase().includes(this.searchTerm.toLowerCase());
        });
      });
    },
  },

  methods: {
    showMore(page) {
      this.page = page;
      this.currentPage = page;
    },

    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.post("/all-signages", {
          userId: this.$store.getters.userId,
        });
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.signages = data.message;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.signages {
  @apply grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8;
}
</style>
