<template>
  <section class="mt-8">
    <div class="mt-8">
      <label for="lga">Operating Local Government Area</label>
      <v-select
        placeholder="Choose your operating LGA"
        label="label"
        v-model="form.lga"
        :reduce="(b) => b.value"
        :options="$store.state.lgas"
      ></v-select>
    </div>

    <div class="mt-8">
      <label for="town">Operating Town</label>
      <v-select
        label="label"
        :reduce="(b) => b.value"
        :options="$store.getters.towns(form.lga)"
        v-model="form.town"
        placeholder="Choose your town"
      ></v-select>
    </div>

    <div class="mt-8">
      <label for="area">Operating Area</label>
      <v-select
        label="label"
        :reduce="(b) => b.value"
        :options="$store.state.areas"
        placeholder="Choose your area"
        v-model="form.area"
      ></v-select>
    </div>

    <div class="mt-8">
      <label for="street">Operating Street</label>
      <v-select
        label="label"
        :reduce="(b) => b.value"
        :options="$store.state.streets"
        placeholder="Choose your street"
        v-model="form.street"
      ></v-select>
    </div>

    <div class="mt-6">
      <button
        :disabled="!proceedReady || proceeding"
        type="submit"
        @click="proceed"
        class="form-button"
      >
        <span class="mr-1">Proceed</span>
        <spinner v-if="proceeding" />
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "BusinessLocation",

  data() {
    return {
      proceeding: false,
      form: {
        lga: null,
        town: null,
        area: null,
        street: null,
      },
      options: [{ label: "Small", value: "small" }],
    };
  },

  watch: {
    "form.lga"() {
      // wipe all options in store
      this.$store.commit("wipeLocations");
    },
    "form.town"(townId) {
      this.$store.dispatch("getAreas", townId);
    },
    "form.area"(areaId) {
      this.$store.dispatch("getStreets", areaId);
    },
  },

  computed: {
    page() {
      return Number(this.$route.query.st) + 1;
    },
    proceedReady() {
      return (
        this.form.lga && this.form.town && this.form.area && this.form.street
      );
    },
  },

  methods: {
    async proceed() {
      try {
        this.proceeding = true;

        this.$store.commit("addTonewRegister", this.form);

        this.proceeding = false;

        this.$router.push({
          path: this.$route.path,
          query: { st: this.page },
        });
      } catch (err) {
        console.log(err);
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped></style>
