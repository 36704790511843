<template>
  <section class="mt-8">
    <div class="green-card">
      <p>NIN Data verified successfully. See records <br />below</p>
    </div>

    <div class="flex justify-center items-center mt-6">
      <img
        :src="$store.state.newRegister.picture"
        alt="user picture"
        style="object-fit: contain"
        class="rounded-full h-30 w-30"
      />
    </div>

    <div v-if="showit" class="mt-8">
      <label for="enumType">Enumeration type</label>
      <input
        type="text"
        readonly
        class="input"
        id="enumType"
        v-model="form.enumType"
      />
    </div>

    <div class="mt-8">
      <label for="firstName">First name</label>
      <input
        type="text"
        readonly
        class="input"
        id="firstName"
        placeholder="Enter your first name"
        v-model="$store.state.profile.firstName"
      />
    </div>

    <div class="mt-8">
      <label for="surname">Surname</label>
      <input
        type="text"
        readonly
        class="input"
        id="surname"
        placeholder="Enter your surname"
        v-model="$store.state.profile.surname"
      />
    </div>

    <div class="mt-8">
      <label for="otherNames">Other names</label>
      <input
        type="text"
        readonly
        class="input"
        id="otherNames"
        placeholder="- include your other name here -"
        v-model="$store.state.profile.middleName"
      />
    </div>

    <div class="mt-8">
      <label for="nin">NIN</label>
      <input
        type="number"
        readonly
        class="input"
        id="nin"
        pattern="/^-?\d+\.?\d*$/"
        onKeyPress="if(this.value.length==11) return false;"
        placeholder="Enter your NIN"
        v-model="$store.state.newRegister.nin"
      />
    </div>

    <div class="mt-8">
      <label for="phone">Phone number</label>
      <input
        type="number"
        readonly
        class="input"
        id="phone"
        pattern="/\d*$/"
        onKeyPress="if(this.value.length==11) return false;"
        v-model="$store.getters.userPhone"
      />
    </div>

    <div class="mt-6">
      <button
        :disabled="!formReady || processing"
        type="submit"
        @click="proceed"
        class="form-button"
      >
        <span class="mr-1">Proceed</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "ShowNinData",

  data() {
    return {
      showit: false,
      processing: false,
      form: {
        enumType: "Individual",
        firstName: "John",
        surname: "Okor",
        otherNames: "Winter",
        nin: "82898327375",
        phone: "09087687210",
      },
    };
  },

  computed: {
    formReady() {
      return this.form.phone && this.form.phone.length == 11;
    },
  },

  methods: {
    async proceed() {
      try {
        this.processing = true;

        // const res = await this.$http.post("/verify-nin", this.form);
        this.processing = false;

        // if (!res) {
        //   return;
        // }

        // const { data } = res;
        // this.$store.commit("addTonewRegister", data.message);
        // this.$store.commit("addTonewRegister", this.form);

        this.$router.push({
          name: "register-unregistered-business",
          query: { st: 5 },
        });
      } catch (err) {
        console.log(err);
        this.processing = false;
      }
    },
  },
};
</script>

<style scoped>
.green-card {
  @apply py-3 px-5 lg:px-10 flex flex-col justify-center text-black text-center font-bold text-xs w-full;
  background: #f3fef5;
  border: 1px solid #9cd1b8;
  border-radius: 12px;
}
</style>
