<template>
  <div class="border border-turqGreen rounded-xl">
    <div class="tab__header">
      <a
        href="#"
        class="tab__link p-4 bg-blue-dark hover:bg-blue-darker no-underline flex justify-between"
        @click.prevent="active = !active"
      >
        <span class="font-mediium">{{ title }}</span>

        <span
          class="bg-cadet text-white font-medium text-xs px-4 py-1 rounded"
          v-show="!active"
          >View</span
        >
        <span
          class="bg-turqGreen text-cadet font-medium text-xs px-4 py-1 rounded"
          v-show="active"
          >Collapse</span
        >
      </a>
    </div>
    <div class="tab__content px-4 p-2" v-show="active"><slot /></div>
  </div>
</template>

<script>
export default {
  name: "Accordion",

  props: {
    title: {
      type: String,
      default: "Header",
    },
  },

  data() {
    return {
      active: false,
    };
  },
};
</script>

<style scoped></style>
