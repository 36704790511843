<template>
  <div class="p-4 lg:px-18 lg:pt-8">
    <div v-if="getting" class="flex justify-center w-full mt-7">
      <spinner class="w-20 h-20" color="ansGreen" />
    </div>

    <div v-else class="block lg:flex">
      <section class="w-12/12 lg:w-8/12">
        <div class="flex justify-between items-center">
          <div class="flex gap-3 items-center">
            <button @click="$router.go(-1)" class="py-2 px-3">
              <img
                src="@/assets/images/icons/arrow-top-left-sharp.svg"
                alt="icons"
                class="h-6 w-6"
              />
            </button>

            <h4 class="font-bold text-base lg:text-xl">
              <span class="text-pineGray mr-3">Invoice</span>
              <span>{{ invoice.invoice_number }}</span>
            </h4>
          </div>

          <button class="bg-aeroBlue py-1.5 px-2.5 rounded-lg">
            <img
              src="@/assets/images/icons/share.svg"
              alt="icons"
              class="w-5 h-5"
            />
          </button>
        </div>

        <Lines class="" />

        <div class="mt-8">
          <h4 class="font-bold text-base lg:text-xl mb-4 lg:my-8">
            Subscription details
          </h4>

          <div>
            <div class="key">Item Description</div>
            <div class="val">
              {{ invoice.category_name }}, {{ invoice.item_name }},
              {{ invoice.subscription_name }}
            </div>
          </div>

          <div class="mt-5">
            <div class="key">Subscription period</div>
            <div class="val">
              {{ invoice.dateof_subscription | moment("Do MMMM, YYYY") }} -
              {{ invoice.duration | moment("Do MMMM, YYYY") }}
            </div>
          </div>

          <div class="mt-5">
            <div class="key">Subscription cost</div>
            <div class="val">{{ invoice.amount | toCurrency }}</div>
          </div>

          <div class="mt-5">
            <div class="key">Next payment date</div>
            <div class="vale">
              <p class="failed">
                {{ invoice.duration | moment("Do MMMM YYYY") }}
              </p>
              <p v-if="showit" class="font-medium text-xs text-cadet mt-2">
                Your payment is 5 days behind
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="w-12/12 lg:w-4/12 ml-0 lg:ml-5 mt-8 lg:mt-0">
        <PaymentCard
          :paymentInfo="{
            cost: invoice.amount,
            asset_code: invoice.asset_code,
          }"
        />

        <div v-if="showit">
          <button
            class="rounded-xl border border-black bg-black text-white text-sm text-center w-full mt-6 py-3.5"
          >
            Discard this invoice and generate a new one
          </button>

          <p class="text-xs text-cadet text-center mt-1">
            This allows you to modify your existing
            <b>subscription <br />details</b>
            for this asset before paying
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Lines from "@/components/Lines.vue";
import PaymentCard from "@/components/app/PaymentCard.vue";

export default {
  name: "Invoice",

  components: {
    Lines,
    PaymentCard,
  },

  props: ["id"],

  async created() {
    await this.getData();
  },

  data() {
    return {
      showit: false,
      getting: false,
      invoice: null,
    };
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.post("/get-single-transaction", {
          invoice_id: this.id,
        });
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.invoice = data.message;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.key {
  @apply rounded-xl w-full p-2.5 bg-white text-cadet font-bold text-xs;
  border: 8px solid #f3fef5;
}
.val {
  @apply rounded-xl w-full p-2.5 bg-ghostWhite mt-4 font-bold text-xs lg:text-base;
  border: 8px solid #f9f9f9;
}
.vale {
  @apply rounded-xl w-full bg-ghostWhite mt-4 font-bold text-xs lg:text-base;
  border: 8px solid #f9f9f9;
}
.vale .failed {
  @apply bg-red-100 text-black p-2.5 rounded-xl;
}

.pcard {
  @apply rounded-xl border border-turqGreen pb-6 w-full;
}
</style>
