<template>
  <section class="mt-8">
    <div class="green-card">
      <p>
        Generate your virtual NIN by dialing this USSD which was also sent to
        your phone
      </p>

      <p class="mt-2">{{ $store.state.newRegister.vMessage }}</p>
    </div>

    <div class="pt-8">
      <label for="virtualNin">Virtual NIN</label>
      <input
        type="text"
        autofocus
        class="input"
        id="virtualNin"
        pattern="/^-?\d+\.?\d*$/"
        placeholder="Enter the virtual NIN you generated"
        v-model="form.virtualNin"
        v-uppercase
      />
    </div>

    <div class="mt-6">
      <button
        :disabled="!ninReady || processing"
        type="submit"
        @click="processNin"
        class="form-button"
      >
        <span class="mr-1">Proceed</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "VirtualNin",

  data() {
    return {
      processing: false,
      form: {
        virtualNin: null,
        userId: this.$store.getters.userId,
      },
    };
  },

  computed: {
    ninReady() {
      return this.form.virtualNin && this.form.virtualNin.length > 10;
    },
  },

  methods: {
    async processNin() {
      try {
        this.processing = true;

        const res = await this.$http.post("/verify-virtual-nin", this.form);
        this.processing = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.$store.commit("addTonewRegister", data.data);
        this.$store.commit("updateProfile", data.data);

        /** if coming from profile **/
        const goingTo = this.$store.state.newRegister.from;
        if (goingTo) {
          this.$swal({
            icon: "success",
            text: "data.message",
            // text: data.message,
          });

          this.$router.push({
            name: goingTo,
          });
          return;
        }

        this.$router.push({
          name: "register-unregistered-business",
          query: { st: 4 },
        });
      } catch (err) {
        console.log(err);
        this.processing = false;
      }
    },
  },
};
</script>

<style scoped>
.green-card {
  @apply py-6 px-5 lg:px-10 flex flex-col justify-center text-black text-center font-bold text-xs w-full;
  background: #f3fef5;
  border: 1px solid #9cd1b8;
  border-radius: 12px;
}
</style>
