<template>
  <div
    class="flex justify-between bg-darkGreen rounded-xl pl-5 pr-4 pt-5 lg:pl-6 lg:pr-5 lg:pt-6"
  >
    <div class="w-9/12">
      <div class="font-bold text-3xl text-white">
        Register a new<br />signage
      </div>
      <div class="font-medium text-sm text-white my-3 lg:mt-3.5">
        Create a digital signature for your signage and easily make payments
        within the app
      </div>
      <button
        @click="openSignage"
        class="bg-nishGreen text-white block text-xs lg:text-sm font-medium rounded-lg mb-6 mt-4 px-6 py-1.5"
      >
        Start registration
      </button>
    </div>

    <div class="w-4/12 flex flex-col justify-end">
      <img
        src="@/assets/images/signage.png"
        alt="signage image"
        class="w-full h-36 lg:w-30 lg:h-44"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "GreenBox",

  methods: {
    openSignage() {
      this.$store.commit("toggleSignageModal");
    },
  },
};
</script>

<style scoped></style>
