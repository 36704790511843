<template>
  <div
    class="cdl-cover flex w-full justify-between"
    :style="`--stages: ${stages}`"
  >
    <!-- start for 1 stage -->
    <div v-for="st in stages" :key="st" class="flex items-center t-each">
      <span
        class="circle"
        :class="st <= currentStage ? 'border-turqGreen' : 'border-transparent'"
      >
        <span class="dot bg-turqGreen"></span>
      </span>
      <span class="line bg-turqGreen"></span>
    </div>
    <!-- end for 1 stage -->

    <!-- for last one -->
    <span
      class="circle"
      :class="
        currentStage >= stages + 1 ? 'border-turqGreen' : 'border-transparent'
      "
    >
      <span class="dot bg-turqGreen"></span>
    </span>
    <!-- end for last one -->
  </div>
</template>

<script>
export default {
  name: "CirlceDotLines",

  props: {
    stages: {
      type: Number,
      default: 1,
    },
    currentStage: {
      type: [Number, String],
      default: 1,
    },
  },
};
</script>

<style scoped>
.cdl-cover {
  /* @apply w-12/12; */
}
.t-each {
  width: calc((100% - 30px) / var(--stages));
}
div span.circle {
  @apply rounded-full bg-white flex items-center justify-center;
  width: 1.75rem;
  height: 1.75rem;
  border-width: 3px;
}
div span.dot {
  @apply rounded-full;
  width: 0.625rem;
  height: 0.625rem;
}
div span.line {
  @apply rounded-3xl mx-0.5;
  width: calc(100% - 34px);
  height: 4px;
}
/* .cdl-cover div:last-child span.line {
  width: calc(100% - 64px);
} */
</style>
