<template>
  <div class="p-4 lg:px-18 lg:pt-8 block lg:flex">
    <section class="w-12/12 lg:w-8/12">
      <div class="flex gap-2 items-center">
        <button @click="$router.go(-1)" class="py-2 px-3">
          <img
            src="@/assets/images/icons/arrow-top-left-sharp.svg"
            alt="icons"
            class="h-6 w-6"
          />
        </button>

        <h4 class="font-bold text-base lg:text-xl text-pineGray">
          <!-- <span class="">GLO4333 </span> -->
          <span> Outstanding Invoices</span>
        </h4>
      </div>

      <div>
        <div v-if="getting" class="flex justify-center w-full mt-7">
          <spinner class="w-20 h-20" color="ansGreen" />
        </div>

        <div v-else>
          <div v-for="(inv, n) in invoices" :key="n" class="gredy invalid">
            <div class="flex items-center text-xs gap-2">
              <img src="@/assets/images/icons/dollar_red.svg" alt="icons" />

              <div>
                <p>Invoice: {{ inv.asset_code }}</p>
                <p>{{ inv.dateof_subscription | moment("DD/MM/YYYY") }}</p>
              </div>
            </div>

            <router-link :to="{ name: 'invoice', params: { id: inv.id } }">
              View
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="w-12/12 lg:w-4/12 ml-0 lg:ml-5"></section>
  </div>
</template>

<script>
export default {
  name: "Invoices",

  async created() {
    await this.getData();
  },

  data() {
    return {
      getting: false,
      invoices: [],
    };
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.post("/get-unpaid-transaction", {
          userId: this.$store.getters.userId,
          phone: this.$store.getters.userPhone,
        });
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.invoices = data.message;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.gredy {
  @apply flex justify-between items-center mt-8 px-4 py-2 rounded-lg;
}
.gredy.invalid {
  @apply bg-red-100 text-red-700;
}
.gredy.invalid a {
  @apply bg-ansLemon block text-white font-medium text-xs px-4 py-1 rounded;
}
</style>
