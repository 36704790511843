<template>
  <div>
    <div class="former">
      <p>Item Description</p>
      <div>
        Lorem ipsum dolor sit amet consectetur. Duis suspendisse in consequat
        platea. Massa orci lorem senectus rutrum eros aenean eu. Feugiat enim
        sed vel auctor quis mi. Aliquet nisl ipsum id adipiscing accumsan.
        Pulvinar cursus felis id aliquam bibendum. Mi eros vitae mattis sit.
      </div>
    </div>

    <div class="former">
      <p>Subscription period</p>
      <div>1st March, 2023 - 1st June, 2023</div>
    </div>

    <div class="former">
      <p>Next payment date</p>
      <div>2nd June 2023</div>
    </div>

    <div class="former">
      <p>Subscription cost</p>
      <div>N 130,000</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubDetails",
};
</script>

<style scoped>
.former {
  @apply block mt-4;
}
.former p {
  @apply font-redhat text-xs w-full;
  color: #5e6974;
}
.former div {
  @apply font-redhat font-bold text-xs rounded-xl p-4 bg-ghostWhite w-full;
  color: #101011;
}
</style>
