<template>
  <section>
    <div class="pt-8">
      <label for="nin">Confirm NIN</label>
      <input
        autofocus
        type="number"
        class="input"
        id="nin"
        pattern="/^-?\d+\.?\d*$/"
        onKeyPress="if(this.value.length==11) return false;"
        placeholder="Re-Enter your 11-digit NIN"
        v-model="form.nin"
      />
      <small class="text-xs text-red-700" v-if="ninReady && !ninEqual">
        Your NIN does not match
      </small>
    </div>

    <div class="mt-8">
      <label for="phone">Phone number</label>
      <input
        type="number"
        class="input"
        id="phone"
        pattern="/\d*$/"
        onKeyPress="if(this.value.length==11) return false;"
        placeholder="Enter phone number used for your NIN registration"
        v-model="form.phone"
      />
    </div>

    <div class="mt-6">
      <button
        :disabled="!formReady || processing"
        type="submit"
        @click="processNin"
        class="form-button"
      >
        <span class="mr-1">Proceed</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "NinVerify",

  data() {
    return {
      processing: false,
      form: {
        nin: null,
        phone: null,
        userId: this.$store.getters.userId,
      },
    };
  },

  computed: {
    ninEqual() {
      return this.form.nin == this.$store.state.newRegister.nin;
    },
    ninReady() {
      return this.form.nin && this.form.nin.length == 11;
    },

    formReady() {
      return (
        this.ninReady &&
        this.ninEqual &&
        this.form.phone &&
        this.form.phone.length == 11
      );
    },
  },

  methods: {
    async processNin() {
      try {
        this.processing = true;

        const res = await this.$http.post("/verify-nin", this.form);
        this.processing = false;

        if (!res) {
          return;
        }

        const { data } = res;

        if (data.code == "01") {
          this.$router.push("/app");
          return;
        }

        data.data.id = null;
        this.$store.commit("addTonewRegister", {
          ...data.data,
          vMessage: data.message,
        });
        this.$store.commit("updateProfile", { nin: this.form.nin });

        this.$router.push({
          name: "register-unregistered-business",
          query: { st: 3 },
        });
      } catch (err) {
        console.log(err);
        this.processing = false;
      }
    },
  },
};
</script>

<style scoped></style>
