<template>
  <div class="text-black">
    <!-- <b>#{{ item.id }}</b> -->
    <span>{{ item.businessName }}</span>
  </div>
</template>

<script>
export default {
  name: "ItemTemplate",
  props: {
    item: { required: true },
  },
};
</script>
