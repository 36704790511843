<template>
  <div class="border border-nishGreen rounded-xl flex justify-between min-h-11">
    <div class="flex gap-3 items-center pl-3.5 w-11/12">
      <img src="@/assets/images/icons/search.svg" alt="icons" class="h-6 w-6" />

      <form @submit.prevent="search" class="w-10/12">
        <input
          autofocus
          type="text"
          placeholder="Search record"
          class="hover:outline-none focus:outline-none w-full"
          v-model="searchTerm"
        />
      </form>
    </div>

    <button v-if="showFilter">
      <img
        src="@/assets/images/icons/filter.svg"
        alt="icons"
        class="rounded-r-lg"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: "Search",

  props: {
    value: {
      type: String,
      default: null,
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      searchTerm: null,
    };
  },

  methods: {
    search() {
      this.$emit("input", this.searchTerm);
    },
  },
};
</script>

<style scoped>
.min-h-11 {
  min-height: 2.75rem;
}
input::placeholder {
  @apply italic text-sm text-cadet font-normal;
}
</style>
