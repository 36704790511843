<template>
  <section class="mt-8">
    <div class="mt-8">
      <label for="businessName">Business Name</label>
      <input
        v-if="fullDisable"
        readonly
        type="text"
        class="input"
        v-model="form.businessName"
      />

      <vue-suggestion
        v-else
        :items="items"
        v-model="form.business"
        :setLabel="setLabel"
        :itemTemplate="itemTemplate"
        @changed="inputChange"
        @selected="itemSelected"
        :inputOptions="{
          styleClasses: 'tinput',
        }"
        :suggestionOptions="{
          listStyleClasses: '',
          groupStyleClasses:
            'bg-gray-50 rounded-b-xl border-l border-b border-r border-ansLemon py-3',
          groupHeaderStyleClasses: '',
          itemWrapperStyleClasses: 'px-3 hover:bg-green-50',
          itemStyleClasses: 'py-2 text-sm',
        }"
      >
      </vue-suggestion>
    </div>

    <div class="mt-8">
      <label for="businessType">Business type</label>

      <input
        v-if="disableBus"
        readonly
        type="text"
        class="input"
        v-model="form.businessType"
      />

      <v-select
        v-else
        placeholder="Choose a business type"
        label="label"
        v-model="form.businessType"
        :reduce="(b) => b.value"
        :options="$store.state.businessTypes"
      ></v-select>
    </div>

    <div class="mt-8">
      <label for="businessType">Business Category</label>

      <input
        v-if="disableBus"
        readonly
        type="text"
        class="input"
        v-model="form.businessCategory"
      />

      <v-select
        v-else
        label="label"
        placeholder="Choose a business category"
        v-model="form.businessCategory"
        :reduce="(b) => b.value"
        :options="$store.state.businessCategories"
      ></v-select>
    </div>

    <div class="mt-8">
      <label for="businessDescription">Business description</label>
      <textarea
        :disabled="disableBus"
        name="businessDescription"
        id="businessDescription"
        cols="30"
        rows="5"
        placeholder="Describe your business here"
        v-model="form.businessDescription"
      ></textarea>
    </div>

    <div class="mt-6">
      <button
        :disabled="!ninReady || proceeding"
        type="submit"
        @click="proceed"
        class="form-button"
      >
        <span class="mr-1">Proceed</span>
        <spinner v-if="proceeding" />
      </button>
    </div>
  </section>
</template>

<script>
import itemTemplate from "@/components/app/register/ItemTemplate.vue";
export default {
  name: "Unregister5",

  data() {
    return {
      proceeding: false,
      form: {
        business: null,
        businessName: this.$store.state.newRegister.businessName,
        businessType: this.$store.state.newRegister.businessType,
        businessCategory: this.$store.state.newRegister.company_category,
        businessDescription: this.$store.state.newRegister.businessDescription,
      },
      businessTerm: null,
      businesses: [],
      items: [],
      itemTemplate,
      item: null,
      fullDisable: false,
      disableBus: false,
    };
  },

  async created() {
    await this.getData();
  },

  async mounted() {
    if (this.$store.state.newRegister.business_id) {
      this.form.business_id = this.$store.state.newRegister.business_id;
      this.fullDisable = true;
      this.disableBus = true;
    }
    await this.$store.dispatch("getBusinessTypes");
  },

  watch: {
    item(nv) {
      if (nv && typeof nv == "object") {
        this.form.businessName = nv.businessName;
        this.form.businessType = nv.businessType;
        this.form.businessDescription = nv.businessDescription;
        this.disableBus = true;
      }
    },

    items(nv) {
      if (!nv.length) {
        this.disableBus = false;
      }
    },
  },

  computed: {
    filteredBusiness() {
      return this.$store.state.businesses.filter(
        (bus) => bus.businessName == this.form.businessName
      );
    },
    ninReady() {
      return (
        this.form.businessName &&
        this.form.businessType &&
        this.form.businessCategory &&
        this.form.businessDescription
      );
    },
  },

  methods: {
    itemSelected(item) {
      this.item = item;
    },
    setLabel(item) {
      return item.businessName;
    },
    inputChange(text) {
      this.form.businessName = text;
      // your search method
      this.items = this.businesses.filter((item) =>
        item.businessName.toLowerCase().includes(text.toLowerCase())
      );
    },
    async proceed() {
      try {
        this.proceeding = true;
        if (this.form.business) {
          const busin = this.form.business;
          this.form = { ...this.form, ...busin };
          this.form.business = null;
        }

        this.$store.commit("addTonewRegister", this.form);

        this.proceeding = false;

        // go to signage add if business exists
        if (this.form.business_id) {
          this.$router.push({
            name: "register-unregistered-business",
            query: { st: 7 },
          });
          return;
        }

        this.$router.push({
          name: "register-unregistered-business",
          query: { st: 6 },
        });
      } catch (err) {
        console.log(err);
        this.proceeding = false;
      }
    },
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.post("/get-business", {
          userId: this.$store.getters.userId,
          phone: this.$store.getters.userPhone,
        });
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.businesses = data.message.registered_businesses;
        this.$store.commit("saveBusinesses", this.businesses);
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped></style>
