<template>
  <div>
    <Search :showFilter="false" v-model="searchTerm" />

    <div
      v-for="(inv, n) in filteredInvoices"
      :key="n"
      :class="`gredy ${inv.paid == 0 ? 'invalid' : 'valid'}`"
    >
      <div class="flex items-center text-xs gap-2">
        <img
          v-if="inv.paid == 0"
          src="@/assets/images/icons/dollar_red.svg"
          alt="icons"
        />
        <img v-else src="@/assets/images/icons/dollar_black.svg" alt="icons" />

        <div>
          <p class="text-sm font-semibold">Invoice: {{ inv.invoice_number }}</p>
          <p class="text-xs">
            {{ inv.dateof_subscription | moment("DD/MM/YYYY") }}
          </p>
        </div>
      </div>

      <router-link :to="{ name: 'invoice', params: { id: inv.id } }">
        View
      </router-link>
    </div>
  </div>
</template>

<script>
import Search from "@/components/form/Search";
export default {
  name: "Invoices",

  components: {
    Search,
  },

  props: {
    invoices: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      searchTerm: null,
    };
  },

  computed: {
    filteredInvoices() {
      if (!this.searchTerm) return this.invoices;

      return this.invoices.filter((sign) => {
        return Object.values(sign).find((vs) => {
          return `${vs}`.toLowerCase().includes(this.searchTerm.toLowerCase());
        });
      });
    },
  },
};
</script>

<style scoped>
.gredy {
  @apply flex justify-between items-center mt-4 px-4 py-2 rounded-xl;
}
.gredy a {
  @apply bg-ansLemon block text-white font-medium text-xs px-4 py-1 rounded;
}

.gredy.valid {
  @apply bg-ghostWhite text-black;
}
.gredy.invalid {
  @apply bg-red-100 text-red-700;
}
</style>
