<template>
  <div class="pcard">
    <div
      class="flex justify-between items-center bg-ghostWhite p-6 rounded-t-xl"
    >
      <h5 class="font-bold">Payment details</h5>

      <h6 class="font-semibold text-cadet">
        Asset: {{ paymentInfo.asset_code }}
      </h6>
    </div>

    <div class="px-6">
      <div
        class="px-2 py-1.5 flex justify-between bg-mintCream items-center font-bold rounded-xl mt-6"
      >
        <p class="text-cadet">Subscription cost</p>
        <p class="p-2.5 bg-white text-xs">
          {{ paymentInfo.cost | toCurrency }}
        </p>
      </div>

      <button
        @click="$emit('action', 'pay')"
        class="rounded-xl bg-ansGreen border border-ansGreen text-white text-sm text-center w-full mt-6 py-3.5"
      >
        Make payment
      </button>

      <button
        @click="$emit('action', 'save')"
        class="rounded-xl border border-ansGreen text-cadet text-sm text-center w-full mt-6 py-3.5"
      >
        Complete this payment later
      </button>
      <p class="text-sm text-gray-400 mt-1 text-center">
        Return to dashboard and pay at a later date
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentCard",

  props: {
    paymentInfo: {
      type: Object,
      default: () => ({
        cost: 1300000,
        asset_code: "GGG4333",
      }),
    },
  },
};
</script>

<style scoped>
.pcard {
  @apply rounded-xl border border-turqGreen pb-6 w-full;
}
</style>
