<template>
  <div class="rounded-xl">
    <div
      class="bg-nishGreen rounded-t-xl py-3 px-4 flex justify-between items-center font-medium"
    >
      <div class="text-white">{{ signage.asset_code }}</div>
      <router-link
        :to="{ name: 'view-signage', params: { id: signage.signage_id } }"
        class="text-white rounded bg-ansLemon text-sm py-1 px-2.5"
      >
        View asset
      </router-link>
    </div>

    <div class="bg-white p-4 border-body">
      <div class="flex items-center">
        <div>
          <img src="@/assets/images/icons/portfolio.svg" alt="icon" />
        </div>

        <div class="font-semibold text-sm text-black ml-2.5">
          {{ signage.business_name }}
        </div>
      </div>

      <div class="flex items-center mt-3.5">
        <div>
          <img src="@/assets/images/icons/house.svg" alt="icon" />
        </div>

        <div class="font-semibold text-sm text-black ml-2.5">
          {{ signage.signage_business_type }}
        </div>
      </div>

      <div class="flex items-center mt-3.5">
        <div>
          <img src="@/assets/images/icons/calendar.svg" alt="icon" />
        </div>

        <div class="font-semibold text-sm text-black ml-2.5">
          {{ signage.dateof_subscription | moment("Do MMMM, YYYY") }} -
          {{ signage.duration | moment("Do MMMM, YYYY") }}
        </div>
      </div>

      <div class="flex items-center mt-3.5">
        <div>
          <img src="@/assets/images/icons/location.svg" alt="icon" />
        </div>

        <div class="text-xs text-black ml-2.5 pgray">
          {{ signage.street_no }} {{ signage.street_name }},
          {{ signage.area_name }} {{ signage.city }}
        </div>
      </div>

      <div v-if="signage.paid" class="gredy valid">
        <div class="flex items-center text-xs gap-2">
          <img src="@/assets/images/icons/dollar_white.svg" alt="icons" />
          <p>Subscription valid till</p>
        </div>

        <div>12 Dec, 2023</div>
      </div>

      <div v-else class="gredy invalid">
        <div class="flex items-center text-xs gap-2">
          <img src="@/assets/images/icons/dollar_red.svg" alt="icons" />
          <p>Outstanding Invoices ({{ signage.total_invoices }})</p>
        </div>

        <router-link to="">View</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignageCard",

  props: {
    signage: {
      type: Object,
      default: () => ({
        asset_code: "GL8788005",
        street_no: "4810",
        enumerated_on: "2023-03-28T07:43:29.000Z",
        asset_description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et fermentum dui. Ut orci quam, ornare sed lorem sed, hendrerit auctor dolor. Nulla viverra, nibh quis ultrices malesuada, ligula ipsum. ",
        business_name: "COOPERHOUSE HOTEL & TOWER PLC",
        longitude: null,
        latitude: null,
        business_type: 1,
        height: 3,
        width: 4,
        area_name: "AMANSEA",
        street_name: "AGHA ",
        signage_business_type: "Limited Liability",
        asset_type: "Bridges",
        city: "AMANSEA",
        id: 38,
        subscriber_id: "110",
        StateID: null,
        NIN: "1004316954",
        surname: null,
        firstname: null,
        middlename: null,
        subscription_id: 5,
        asset_name: null,
        subitem: null,
        amount: "120000",
        dateof_subscription: "2023-03-02",
        duration: "2023-04-01",
        subscription_status: 1,
        is_autorenewal: 0,
        userid: 29,
        created_at: "2023-03-28T07:43:29.000Z",
        idsignage_invoices: 150,
        tax_id: "1004316954",
        revenue_code: null,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et fermentum dui. Ut orci quam, ornare sed lorem sed, hendrerit auctor dolor. Nulla viverra, nibh quis ultrices malesuada, ligula ipsum. ",
        year: 2023,
        created_by: "38",
        created_on: "2023-03-28T07:43:29.000Z",
        locked: 0,
        paid: 0,
        paid_on: null,
        item_ref: "110",
        invoice_number: "1679989409230",
        town_code: 61,
        area_code: 829,
        street_code: 4810,
        payment_type_id: null,
        zone: null,
        zone_address: null,
        cancel: 0,
        consideration_name: "Signage has light",
        consideration_amount: "50.00",
        is_percentage: 0,
      }),
    },
  },
};
</script>

<style scoped>
.border-body {
  border: 1px solid #9cd1b8;
  border-radius: 0px 0px 12px 12px;
}
.pgray {
  color: #5e6974;
}
.gredy {
  @apply flex justify-between items-center mt-3.5 px-4 py-2 rounded-lg;
}
.gredy.valid {
  @apply bg-ansGreen text-white;
}
.gredy.valid div:last-child {
  @apply bg-ansLemon font-medium text-xs px-2.5 py-1;
  border-radius: 4px;
}
.gredy.invalid {
  @apply bg-red-100 text-red-700;
}
.gredy.invalid a {
  @apply bg-ansLemon block text-white font-medium text-xs px-4 py-1 rounded;
}
</style>
