<template>
  <section class="mt-8">
    <div class="mt-8">
      <label for="category">Signage Category</label>
      <v-select
        label="label"
        :reduce="(b) => b.value"
        :options="$store.state.signCategories"
        placeholder="Choose a signage category"
        v-model="form.category"
      ></v-select>
    </div>

    <div class="mt-8">
      <label for="signageType">Select Signage type</label>
      <v-select
        label="label"
        :reduce="(b) => b.value"
        id="signageType"
        :options="$store.state.signTypes"
        placeholder="Choose a signage type"
        v-model="form.signageType"
      ></v-select>
    </div>

    <div class="mt-8">
      <label for="signageItem">Select Signage Items</label>
      <v-select
        label="label"
        :reduce="(b) => b.value"
        id="signageItem"
        :options="$store.state.signItems"
        placeholder="Choose a signage item"
        v-model="form.signageItem"
      ></v-select>
    </div>

    <div v-if="form.itemHasSub" class="mt-8">
      <label for="signageSubitem">Select Signage Subitems</label>
      <v-select
        label="label"
        :reduce="(b) => b.value"
        id="signageSubitem"
        :options="$store.state.signSubitems"
        placeholder="Choose a signage item"
        v-model="form.signageSubitem"
      ></v-select>
    </div>

    <div class="mt-8">
      <label for="subscription">Select Subscription</label>
      <v-select
        label="label"
        :reduce="(b) => b.value"
        :options="$store.state.signSubscriptions"
        placeholder="Choose a subscription"
        v-model="form.subscriptionId"
      ></v-select>
    </div>

    <div v-if="isFirstParty">
      <div
        class="mt-8 flex justify-between items-center py-2.5 px-3 bg-ghostWhite rounded-lg"
      >
        <div class="text-xs text-charlGreen font-medium w-6/12">
          Signage height
        </div>
        <input
          class="bg-white w-2/12 text-center text-xs py-3 px-1 rounded-lg focus:outline-none focus:border focus:border-ansLemon"
          type="number"
          min="0"
          v-model.number="form.signHeight"
        />
        <div class="bg-gray-100 w-2/12 rounded-lg text-xs text-cadet p-2.5">
          Feet
        </div>
      </div>

      <div
        class="mt-8 flex justify-between items-center py-2.5 px-3 bg-ghostWhite rounded-lg"
      >
        <div class="text-xs text-charlGreen font-medium w-6/12">
          Signage width
        </div>
        <input
          class="bg-white w-2/12 text-center text-xs py-3 px-1 rounded-lg focus:outline-none focus:border focus:border-ansLemon"
          type="number"
          min="0"
          v-model.number="form.signWidth"
        />
        <div class="bg-gray-100 w-2/12 rounded-lg text-xs text-cadet p-2.5">
          Feet
        </div>
      </div>
    </div>

    <div v-if="!calculated" class="mt-6">
      <button
        :disabled="!calcReady || calculating"
        type="submit"
        @click="calculateCost"
        class="form-button"
      >
        <span class="mr-1">Calculate Cost</span>
        <spinner v-if="calculating" />
      </button>
    </div>

    <section v-if="calculated">
      <div v-if="form.subscriptionId">
        <div v-if="form.subscription.consideration_name" class="mt-8">
          <label for="consideration">Subscription Consideration</label>
          <input
            readonly
            type="text"
            name="consideration"
            id="consideration"
            class="input"
            :value="form.subscription.consideration_name"
          />
        </div>

        <div class="mt-8">
          <label for="subCost">Unit cost (&#13217;)</label>
          <input
            readonly
            type="text"
            name="subCost"
            id="subCost"
            class="input"
            :value="form.unit_cost | toCurrency"
          />
        </div>
      </div>

      <div class="mt-8">
        <label for="subCost">Total Subscription Cost</label>
        <input
          readonly
          type="text"
          name="subCost"
          id="subCost"
          class="input"
          :value="form.total_cost | toCurrency"
        />
      </div>

      <div v-if="form.category">
        <div v-if="isFirstParty" class="mt-8">
          <label for="startyear">Choose year</label>
          <v-select
            label="label"
            :reduce="(b) => b.value"
            placeholder="Choose a year"
            id="startyear"
            :options="years"
            v-model="form.year"
          ></v-select>
        </div>

        <div v-else class="mt-8">
          <label for="startDate">Choose start date</label>
          <input
            type="date"
            name="startDate"
            id="startDate"
            class="input"
            v-model="form.startDate"
          />
        </div>
      </div>

      <!-- display endate-->
      <div v-if="form.startDate && endDate" class="mt-8">
        <label for="endDate">End date</label>
        <input
          readonly
          type="text"
          name="endDate"
          id="endDate"
          class="input"
          v-model="endDate"
        />
      </div>

      <div class="text-center mt-11">
        <button
          :disabled="!saveReady"
          @click="gotoReview"
          class="text-center text-ansGreen text-xs"
        >
          Preview this registration
        </button>
      </div>

      <div class="mt-6">
        <button
          :disabled="!saveReady || registering"
          type="submit"
          @click="registerAsset"
          class="form-button"
        >
          <span class="mr-1">Register asset</span>
          <spinner v-if="registering" />
        </button>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: "SignageRegister",

  data() {
    return {
      calculated: false,
      calculating: false,
      registering: false,
      form: {
        category: null,
        signageType: null,
        signageItem: null,
        signageSubitem: null,
        subscriptionId: null,
        subscription: null,
        startDate: null,
        signHeight: 0,
        signWidth: 0,
        itemHasSub: false,
        year: null,
      },
    };
  },

  mounted() {
    this.$store.commit("wipeSignOptions");
  },

  watch: {
    "form.category"(categoryId) {
      // wipe all options in store
      this.$store.commit("wipeSignOptions");
      this.$store.dispatch("getSignTypes", categoryId);
      this.$store.dispatch("getSignItems", categoryId);
    },
    "form.signageItem"(itemId) {
      let item = this.$store.state.signItems.find(
        (item) => item.value == itemId
      );

      this.form.itemHasSub = item ? !!item.has_subitems : false;

      if (this.form.itemHasSub) {
        this.$store.dispatch("getSignSubitems", itemId);
      }

      this.$store.dispatch("getsignSubs", {
        categoryId: this.form.category,
        typeId: this.form.signageType,
        itemId,
      });
    },
    "form.subscriptionId"(subId) {
      this.form.subscription = this.$store.state.signSubscriptions.find(
        (sub) => sub.value == subId
      );
    },
  },

  computed: {
    endDate() {
      return this.form.startDate
        ? this.moment(this.form.startDate)
            .add(this.form.subscription.no_of_days, "d")
            .format("DD/MM/YYYY")
        : null;
    },
    isFirstParty() {
      if (!this.form.category) return false;

      let cat = this.$store.getters.signCategory(this.form.category);
      return !!cat.is_firstparty;
    },
    years() {
      const years = [];
      for (let i = new Date().getFullYear(); i >= 2010; i--) {
        years.push({ label: i, value: i });
      }
      return years;
    },
    calcReady() {
      return !!this.form.subscriptionId && this.firstPartyReady;
    },
    firstPartyReady() {
      return (
        !this.isFirstParty ||
        (this.isFirstParty && this.form.signHeight && this.form.signWidth)
      );
    },
    yearReady() {
      return (this.isFirstParty && this.form.year) || !this.isFirstParty;
    },
    notFirstPartyReady() {
      return !this.isFirstParty && this.form.startDate;
    },
    saveReady() {
      return (
        !!this.form.category &&
        this.form.signageType &&
        this.form.signageItem &&
        this.form.subscriptionId &&
        this.notFirstPartyReady &&
        this.yearReady
      );
    },
  },

  methods: {
    saveForm() {
      this.form.is_firstparty = this.isFirstParty;
      this.$store.commit("addTonewRegister", this.form);
    },

    gotoReview() {
      this.saveForm();
      this.$router.push({ name: "register-review" });
    },

    getDate(y) {
      return this.moment(`${y}`).format("DD-MM-YYYY");
    },

    buildBody(fb) {
      return {
        phone: this.$store.getters.userPhone,
        jtbTin: fb.uniqueCode ? fb.uniqueCode : fb.tin,
        businessName: fb.businessName,
        businessType: fb.type ? fb.type : fb.businessType,
        businessDescription: fb.businessDescription,
        businessCategory: fb.businessCategory,
        town: fb.city ? fb.city : fb.town,
        area: fb.area,
        street: fb.street,
        category: fb.category,
        signageType: fb.signageType,
        subscriptionId: fb.subscription.value,
        date: fb.year ? this.getDate(fb.year) : fb.startDate,
        height: fb.signHeight,
        width: fb.signWidth,
        streetNo: fb.street,
        userId: this.$store.getters.userId,
        has_subitems: fb.itemHasSub,
        subitemid: fb.signageSubitem,
        itemid: fb.signageItem,
        virtualNin: fb.vNIN ? fb.vNIN : this.$store.state.profile.vNin,
        nin: this.$store.getters.userNin ? this.$store.getters.userNin : fb.nin,
        businessId: fb.id ? fb.id : fb.business_id,
        is_firstparty: this.isFirstParty,
      };
    },

    async calculateCost() {
      try {
        this.calculating = true;

        const res = await this.$http.post("/calculate-sub-cost", {
          subscriptionId: this.form.subscriptionId,
          is_firstparty: this.isFirstParty,
          height: this.form.signHeight,
          width: this.form.signWidth,
        });
        this.calculating = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.form = { ...this.form, ...data.message };
        this.calculated = true;
      } catch (err) {
        console.log(err);
        this.calculating = false;
      }
    },

    async registerAsset() {
      try {
        this.saveForm();

        const result = await this.$swal({
          icon: "question",
          title:
            "Are you sure you want to register this asset without preview?",
          text: "This action can not be reversed",
          showDenyButton: true,
          confirmButtonText: "Proceed",
          denyButtonText: "Cancel",
          allowOutsideClick: false,
        });

        if (!result.isConfirmed) {
          return;
        }

        this.registering = true;

        let formBody = this.$store.state.newRegister;
        // format and rename the input
        let url =
          formBody.id || formBody.business_id
            ? "/add-signage"
            : "/self-enumerate";
        formBody = this.buildBody(formBody);

        const res = await this.$http.post(url, formBody);
        this.registering = false;

        if (!res) {
          return;
        }

        const { data } = res;

        const asset = data.asset ? data.asset : data.data;
        this.$store.commit("addTonewRegister", {
          registered: true,
          ...asset,
        });

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$router.push({ name: "register-review" });
      } catch (err) {
        console.log(err);
        this.registering = false;
      }
    },
  },
};
</script>

<style scoped></style>
